<template>
  <div>
    <breadCrumb title="相比" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container text-left">
        <div class="bg-white shadow-sm rounded">
          <div class="p-3 border-bottom d-flex justify-content-between align-items-center">
              <div class="fs-15 fw-600">比较</div>
              <a  style="text-decoration: none;" class="btn btn-soft-primary btn-sm fw-600">重置比较列表</a>
          </div>
          
          <div class="p-3">
            <table class="table table-responsive table-bordered mb-0">
              <thead>
                <tr>
                  <th scope="col" style="width:16%" class="font-weight-bold">
                    名称
                  </th>
                  <th scope="col" style="width:28%" class="font-weight-bold" v-for="(th, i) in compareList" :key="i">
                    <a class="text-reset fs-15">{{ th.name }}</a>
                  </th>
                </tr>
              </thead>
                <tbody>
                  <tr>
                    <th scope="row">图片</th>
                    <td v-for="(td, i) in compareList" :key="`td1${i}`">
                      <img loading="lazy" :src="td.url"  class="img-fluid py-4">
                    </td>
                  </tr>
                    <tr>
                        <th scope="row">价格</th>
                        <td v-for="(td, i) in compareList" :key="`td2${i}`">
                            <del class="fw-600 opacity-50 mr-1">199</del>
                            <span class="fw-700 text-primary">100</span>
                        </td>
                    </tr>
                    <tr>
                      <th scope="row">品牌</th>
                      <td v-for="(td, i) in compareList" :key="`td3${i}`">
                        {{td.companyName}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">类别</th>
                      <td v-for="(td, i) in compareList" :key="`td4${i}`">
                        {{td.categoryName}}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td class="text-center py-4"  v-for="(td, i) in compareList" :key="`td5${i}`">
                          <button type="button" class="btn btn-primary fw-600" >
                            添加到购物车
                          </button>
                      </td>
                    </tr>
                </tbody>
            </table>
          </div>
          
          <div class="text-center p-4" v-if="compareList.length == 0">
              <p class="fs-17">您的比较列表为空</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: '相比',
          path: 'blog'
        },
      ],
      compareList: [
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          companyName: '阿迪达斯',
          categoryName: '女装'
        },
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          companyName: '阿迪达斯',
          categoryName: '女装'
        },
        {
          url: require('../../assets/imgs/goods1.jpg'),
          name: '商品名称商品名称商品名称商品名称商品名称商品名称商品名称商品名称',
          companyName: '阿迪达斯',
          categoryName: '女装'
        },
      ],
     
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'blogDetail'
      })
    }
  }
}
</script>